import { Markdown } from '../components/Markdown';
import { render } from 'solid-js/web';
import { BaseIntersectionController } from '../utils/base_intersection_controller';

// Connects to data-controller="markdown"
export default class extends BaseIntersectionController {
  elementIntersected({ target }: IntersectionObserverEntry) {
    const content = target.getAttribute('data-content')!;
    const dataClass = target.getAttribute('data-class')!;
    const inputProps = {
      id: target.getAttribute('data-id')!,
      name: target.getAttribute('data-name')!,
    };
    const isEditable = target.getAttribute('data-editable')! === 'true';
    target.removeAttribute('data-content');
    render(
      () => (
        <Markdown
          isEditable={isEditable}
          content={content}
          class={dataClass}
          inputProps={inputProps}
        />
      ),
      target
    );
  }
}
